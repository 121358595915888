<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="设置销售模式"
           :afterClose="remove"
           :width="700"
           @cancel="remove"
           @ok="handleOk">
    <a-form-model :labelCol="{ span: 3 }"
                  :wrapperCol="{ span: 21 }"
                  :model="formData">
        <a-form-model-item class="mb-2" label="产品名">
          <span>{{goodsName}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="sale_model" label="销售模式">
          <div>
            <a-radio-group v-model="formData.sale_model">
              <a-radio v-for="(item,i) in Object.keys(saleModel)"
                  :key="i"
                  :value="Number(item)">{{ formatSaleModel(item) }}</a-radio>
            </a-radio-group>
            <div>
              <!-- 1. 预约 -->
              <template v-if="formData.sale_model == 1">
                <a-form-model-item class="mb-2 flex" label="开售时间" prop="sale_start_time">
                  <a-date-picker style="width:100%;" placeholder="开售时间（用户可见）" 
                        :show-time="{ format: 'HH:mm' }"
                        format="YYYY-MM-DD HH:mm" value-format="YYYY-MM-DD HH:mm" 
                        v-model="formData.sale_start_time" />
                </a-form-model-item>
                <a-form-model-item class="mb-2 flex" label="微信推送" prop="wx_subscription_event_id">
                  <a-select placeholder="输入 微信订阅活动名 检索"
                            v-model="formData.wx_subscription_event_id"
                            show-search
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @search="handlerSuggestWxSubscribe">
                    <a-select-option v-for="item in suggestWxSubscribeList"
                             :key="item.id"
                             :value="Number(item.id)">{{ item.name }} {{item.push_time}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </template>
              <!-- 2. 预售 -->
              <div v-if="formData.sale_model == 2" class="flex items-center">
                <a-radio-group button-style="solid" 
                  v-model="formData._delivery_time_mode" @change="onChangeDeliveryTimeMode">
                  <a-radio-button value="all">范围</a-radio-button>
                  <a-radio-button value="start">x日后</a-radio-button>
                  <a-radio-button value="end">x日前</a-radio-button>
                </a-radio-group>
                <template v-if="formData._delivery_time_mode=='all'">
                  <a-range-picker class="ml-4 mr-2" style="width:240px;"
                        valueFormat="YYYY-MM-DD" v-model="dlvr_date" />
                </template>
                <template v-if="formData._delivery_time_mode=='start'">
                  <a-date-picker class="ml-4 mr-2" style="width:150px;" placeholder="发货日期"
                    format="YYYY-MM-DD" value-format="YYYY-MM-DD" 
                    v-model="formData.delivery_start_time" />后发货
                </template>
                <template v-if="formData._delivery_time_mode=='end'">
                  <a-date-picker class="ml-4 mr-2" style="width:150px;" placeholder="发货日期" 
                    format="YYYY-MM-DD" value-format="YYYY-MM-DD" 
                    v-model="formData.delivery_end_time" />前发货
                </template>

              </div>
                
              <!-- 3. 限量预售 -->
              <template v-if="formData.sale_model == 3">
                <a-form-model-item class="mb-2 flex" label="售卖日期" prop="sale_start_time">
                  <a-range-picker style="width:100%"
                        valueFormat="YYYY-MM-DD"
                        v-model="sale_date" />
                </a-form-model-item>
                <a-form-model-item class="mb-2 flex" label="发货日期" prop="delivery_start_time">
                  <a-range-picker style="width:100%"
                        valueFormat="YYYY-MM-DD"
                        v-model="dlvr_date" />
                </a-form-model-item>
                <!-- 限量库存 -->
                <base-table rowKey="sku_id"
                            :columnsData="columns"
                            :tableData="tableList"
                            :customHeight="300">
                  <template #initial_stock="{ record }">
                    <a-input-number size="small" style="width:100%;" 
                          :min="0" :max="100000" :step="1"
                          v-model="record.initial_stock" />
                  </template>
                  <template #stock="{ record }">
                    <a-input-number size="small" style="width:100%;" 
                          :min="0" :max="100000" :step="1"
                          v-model="record.stock" />
                  </template>
                </base-table>
              </template>
            </div>
          </div>
        </a-form-model-item>
        
        <!-- 限售 -->
        <a-form-model-item class="mb-2" label="限购" prop="restricted_num">
          <div class="flex items-center" style="height:40px;">
            <a-radio-group v-model="restrictedMode">
              <a-radio v-for="key in Object.keys(restrictedModeList)"
                  :key="key" :value="Number(key)"
                  @change="handlerChangeRestricted">{{ restrictedModeList[key] }}</a-radio>
            </a-radio-group>
            <template v-if="restrictedMode > 0">
              <a-input-number placeholder="限购x件"
                        class="ml-4" style="width:150px;"
                        :min="0" :max="100000" :step="1"
                        v-model="formData.restricted_num" />
            </template>
          </div>
        </a-form-model-item>
        <!-- 组合装/起售数量 -->
        <a-form-model-item v-if="!formData.sale_model" class="mb-2" label="组合装" prop="least_buy_num">
          <div class="flex items-center" style="height:40px;">
            <a-switch style="width:70px;"
                checked-children="起购数" un-checked-children="非组合"
                v-model="isLeasted" @change="handlerChangeLeasted" />
            <a-input-number v-if="isLeasted" placeholder="x个起购"
                      class="ml-4" style="width:150px;"
                      :min="0" :max="100000" :step="1"
                      v-model="formData.least_buy_num" />
          </div>
        </a-form-model-item>

    </a-form-model>
  </a-modal>

</template>

<script>
import { saleModel, formatSaleModel } from "@/utils/type";
import { getGoodsDetail, setSaleModel } from '@/api/goods.js'
import { 
  getWxSubscribeList
} from "@/api/activity/wx-subscribe.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    goodsDetail: {
      type: Object,
      default: null
    },
    goodsId: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      saleModel,
      formatSaleModel,

      restrictedModeList: {
        0: "不限购",
        1: "每人限购",
        2: "每人每日限购",
      },
      restrictedMode: 0,

      goodsName: "",

      sale_date: [],
      dlvr_date: [],
      formData: {},

      columns: [
        {
          title: '口味',
          dataIndex: 'flavor_name',
          align: 'center',
        },
        {
          title: '尺寸',
          dataIndex: 'specification_name',
          align: 'center',
        },
        {
          title: '限量库存上限',
          dataIndex: 'initial_stock',
          align: 'center',
          width: 120,
          slots: {
            customRender: 'initial_stock'
          },
        },
        {
          title: '限量库存剩余',
          dataIndex: 'stock',
          align: 'center',
          width: 120,
          slots: {
            customRender: 'stock'
          },
        },
      ],
      tableList: [],

      suggestWxSubscribeList: [],
      timerSuggestWxSubscribe: null,

      isLeasted: false,
    }
  },

  async mounted () {
    let goodsDetail
    let skuList = []

    if(this.goodsId){
      const {code,data} = await getGoodsDetail({ goods_id: this.goodsId })
      if(code == 0){
        goodsDetail = data
      }
    }else{
      goodsDetail = this.goodsDetail
    }
    goodsDetail.sku_data.map((item) => {
      if (item.specification_list.length > 0) {
        item.specification_list.map((el) => {
          skuList.push({ 
            ...el,
            flavor_id: item.flavor_id,
            flavor_name: item.flavor_name,
          })
        })
      }
    })

    this.goodsName = goodsDetail.name

    this.formData = {
      goods_id:       goodsDetail.goods_id,
      sale_model:     goodsDetail.sale_model || 0,
      wx_subscription_event_id: goodsDetail.wx_subscription_event_id || undefined,
      sale_start_time:     goodsDetail.sale_start_time || "",
      delivery_start_time: goodsDetail.delivery_start_time || "",
      delivery_end_time:   goodsDetail.delivery_end_time || "",
      restricted_num:      goodsDetail.restricted_num || 0,
      restricted_mode:     goodsDetail.restricted_mode || 1,
      least_buy_num:       goodsDetail.least_buy_num || 0,

      _delivery_time_mode: (goodsDetail.delivery_start_time&&goodsDetail.delivery_end_time)?"all":(goodsDetail.delivery_end_time ? "end" : "start"),
    }
    
    if(goodsDetail.sale_model == 1){
      if(goodsDetail.wx_subscription_event_id){
        this.suggestWxSubscribeList = [{
          id: goodsDetail.wx_subscription_event_id,
          name: goodsDetail.wx_subscription_event_name
        }]
      }
    }
    if(goodsDetail.sale_model == 2){
      this.dlvr_date = [
        goodsDetail.delivery_start_time,
        goodsDetail.delivery_end_time,
      ]
    }
    if(goodsDetail.sale_model == 3){
      this.sale_date = [
        goodsDetail.sale_start_time,
        goodsDetail.sale_end_time,
      ]
      this.dlvr_date = [
        goodsDetail.delivery_start_time,
        goodsDetail.delivery_end_time,
      ]
    }
    this.restrictedMode = goodsDetail.restricted_num>0 ? goodsDetail.restricted_mode : 0
    this.isLeasted = goodsDetail.least_buy_num > 0

    const list = []
    skuList.map(el=>{
      if(el.sku_id){
        list.push(el)
      } 
    })
    this.tableList = list
  },

  methods: {

    async handleOk () {
      const params = this.formData

      if(this.formData.sale_model == 1){
        if(!this.formData.wx_subscription_event_id){
          this.$message.info('请选择微信订阅活动')
          return
        }
        if(!this.formData.sale_start_time){
          this.$message.info('请选择开售时间')
          return
        }
      }
      if(this.formData.sale_model == 2){
        if(this.formData._delivery_time_mode == "all"){
          if(this.dlvr_date.length < 2){
            this.$message.info('请选择发货时间')
            return
          }
          params.delivery_start_time = this.dlvr_date[0].substr(0,10) + " 00:00:00"
          params.delivery_end_time   = this.dlvr_date[1].substr(0,10) + " 23:59:59"
        }else if(this.formData._delivery_time_mode == "end"){
          if(!this.formData.delivery_end_time){
            this.$message.info('请选择发货时间')
            return
          }
          params.delivery_start_time = ""
          params.delivery_end_time = this.formData.delivery_end_time.substr(0,10) + " 23:59:59"
        }else if(this.formData._delivery_time_mode == "start"){
          if(!this.formData.delivery_start_time){
            this.$message.info('请选择发货时间')
            return
          }
          params.delivery_start_time = this.formData.delivery_start_time.substr(0,10) + " 00:00:00"
          params.delivery_end_time = ""
        }
      }
      if(this.formData.sale_model == 3){
        if(this.dlvr_date.length != 2){
          this.$message.info('请选择发货时间')
          return
        }
        if(this.sale_date.length == 2){
          params.sale_start_time = this.sale_date[0].substr(0,10) + " 00:00:00"
          params.sale_end_time   = this.sale_date[1].substr(0,10) + " 23:59:59"
        }else{
          params.sale_start_time = ""
          params.sale_end_time   = ""
        }
        params.delivery_start_time = this.dlvr_date[0].substr(0,10) + " 00:00:00"
        params.delivery_end_time   = this.dlvr_date[1].substr(0,10) + " 23:59:59"
      }

      // 限购
      if(this.restrictedMode > 0){
        params.restricted_mode = this.restrictedMode
        params.restricted_num = parseInt(this.formData.restricted_num)
        if(!params.restricted_num>0){
          this.$message.info('请填写限购数量')
          return
        }
      }else{
        params.restricted_mode = 1
        params.restricted_num = 0
      }

      // 组合装 起购
      if(this.formData.sale_model > 0){
        this.isLeasted = false
      }
      if(this.isLeasted){
        params.least_buy_num = parseInt(this.formData.least_buy_num)
        if(!params.least_buy_num>1){
          this.$message.info('请填写正确的起购数量')
          return
        }
      }else{
        params.least_buy_num = 0
      }

      params.sku_data = JSON.stringify(this.tableList.map(el=>{
        return {
          sku_id:        el.sku_id,
          initial_stock: el.initial_stock,
          stock:         el.stock,
        }
      }))

      const res = await setSaleModel(params)
      if(res.code == 0){
        this.$message.success('已保存')
        this.$emit('ok')
        this.$emit('update:show', false)
      } 
    },

    remove () {
      this.$emit('update:show', false)
    },

    onChangeDeliveryTimeMode(){
      if(this.formData._delivery_time_mode == "start"){
        this.formData.delivery_end_time = ""
      }else if(this.formData._delivery_time_mode == "end"){
        this.formData.delivery_start_time = ""
      }
    },

    async handlerSuggestWxSubscribe (keyword) {
      if(this.timerSuggestWxSubscribe){
        clearTimeout(this.timerSuggestWxSubscribe)
        this.timerSuggestWxSubscribe = null
      }
      this.timerSuggestWxSubscribe = setTimeout(()=>{
        this.getSuggestWxSubscribe(keyword)
      }, 100)
    },
    async getSuggestWxSubscribe(keyword){
      const params = {
        name: keyword || "",
        // name_interior: keyword,
        return_num_count: 1,
      }
      const { data, code } = await getWxSubscribeList(params)
      if (code === 0) {
        this.suggestWxSubscribeList = data.list
      }
    },

    handlerChangeRestricted(val){
      if(val){
        this.isLeasted = false
      }
    },
    handlerChangeLeasted(val){
      if(val){
        this.restrictedMode = 0
      }
    },

  }
}
</script>

<style>
</style>
